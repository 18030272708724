import request from './request'

const api = {
	// 获取角色列表
  rolelist: '/group/lists',
  // 启用禁用角色
  attr: '/group/attr',
  // 树形结构菜单（角色权限）
  treeMenu: '/menu/treeMenu',
  // 添加角色
  add: '/group/add',
  // 编辑角色
  edit: '/group/edit',
  // 获取角色信息
  info: '/group/info',
  // 删除角色
  dele: '/group/del',
  
}

export { request, api }
