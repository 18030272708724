<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="80px">
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
				</el-form-item>
				<el-form-item label="角色名称" prop="name">
					<span class="xx">*</span>
					<el-input type="text" clearable v-model="ruleForm.name" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="权限菜单" prop="password">
					<span class="xx">*</span>
					<div class="tree">
						<el-tree @check="(click, checked)=>{handleCheckChange(click, checked)}"
							:default-expanded-keys="expandedkeys" :default-checked-keys="checkedkeys" :data="data"
							:props="defaultProps" show-checkbox node-key="id" />
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/role";
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'

	export default {
		props: ['title', 'mid'],
		data() {
			return {
				selectedElement: [],
				selectedList: [],
				IdList: [],
				ruleForm: {
					name: '',
					orderby: 0,
					menus: ''
				},
				defaultProps: {
					children: 'children',
					label: 'name',
				},
				expandedkeys: [],
				checkedkeys: [],
				data: [],
				yilist: [],
				erlist: [],
				sanlist: [],
				silist: []
			}
		},
		mounted() {
			this.getrouter()
			if (this.title == '编辑') {
				this.getuser()
			}
		},
		methods: {
			// 获取角色信息
			getuser() {
				this.$http.post(api.info, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 200) {
							// 获取赋值
							this.ruleForm.id = res.data.id
							this.ruleForm.name = res.data.name
							this.ruleForm.orderby = res.data.orderby
							this.ruleForm.menus = res.data.menus

							this.selectedElement = res.data.menus
							this.IdList = res.data.menus
							delete this.ruleForm.create_time

							// 遍历
							res.data.menus_arr.forEach(item => {
								this.yilist.push(item.id)
								if (item.children) {
									item.children.forEach(eritem => {
										if (eritem.children) {
											this.erlist.push(eritem.id)
											console.log(eritem.id)
											eritem.children.forEach(sanitem => {
												if(sanitem.children) {
													sanitem.children.forEach(siitem => {
														this.silist.push(siitem.id)
													})
												}else {
													this.sanlist.push(sanitem.id)
												}
											})
											
										}else {
											this.sanlist.push(eritem.id)
										}
									})
								}
							})
							// 详情选中
							this.expandedkeys = this.yilist.concat(this.erlist)
							this.checkedkeys = this.sanlist.concat(this.silist)
						}
					})
			},
			// 选中
			handleCheckChange(click, checked) {
				console.log(checked.checkedNodes)
				this.selectedList = []
				this.selectedList = checked.halfCheckedNodes.concat(checked.checkedNodes)
			},
			// 获取路由
			getrouter() {
				this.$http.post(api.treeMenu)
					.then((res) => {
						if (res.code == 200) {
							this.data = res.data
						}
					})
			},
			// 确定
			SubmitForm() {
				// // 遍历权限
				if (this.selectedList.length > 0) {
					this.IdList = []
					this.selectedList.forEach(item => {
						this.IdList.push(item.id)
					})
				}
				this.ruleForm.menus = this.IdList.join(',')
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入角色名称',
						type: 'warning',
					})
				} else if (this.ruleForm.menus === '') {
					ElMessage({
						message: '请选择权限菜单',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}

					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
